<template>
  <v-menu
    offset-y
    transition="slide-y-transition"
    content-class="share-menu">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        rounded
        :color="color"
        height="28"
        width="70"
        class="share-btn"
        elevation="0"
      >
        <span class="text-capitalize">{{ $t("me.share") }}</span>
        <img src="/images/share.svg">
      </v-btn>
    </template>
    <v-list color="secondary" width="145" height="102" class="py-4">
      <v-list-item class="px-6" @click="$emit('share', 'facebook')">
        <v-list-item-avatar class="my-0 mr-3" width="25" height="25" min-width="25"><img src="/images/fb.svg"></v-list-item-avatar>
        <v-list-item-title class="white--text">Facebook</v-list-item-title>
      </v-list-item>
      <v-list-item class="px-6" @click="$emit('share', 'linkedin')">
        <v-list-item-avatar class="my-0 mr-3" width="25" height="25" min-width="25"><img src="/images/ln.svg"></v-list-item-avatar>
        <v-list-item-title class="white--text">LinkedIn</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'share',
  props: {
    color: {
      type: String,
      default: '#A9DDDE'
    }
  }
};
</script>
