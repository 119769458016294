<template>
  <div class="profile">
    <div class="user-block">
      <div class="avatar">
        <img :src="currentUser.picture">
      </div>
      <h3 class="name">{{ currentUser.name }}</h3>
      <div class="email">{{ currentUser.email }}</div>
    </div>
    <div class="title-wrap d-flex justify-space-between">
      <div>
        <h3 class="page-title">
          {{ $t("history.record") }}
        </h3>
        <h5 class="sub-title">
          {{ $t("history.since") }} {{ $d($moment(currentUser.createdAt), 'moye') }}
        </h5>
      </div>
      <div>
        <social-sharing
          ref="socialSharing"
          :url="shareUrl"
          :quote="title"
          hashtags="breadline"
          inline-template>
          <span></span>
        </social-sharing>

        <sharing @share="network => $refs.socialSharing.share(network)" type="profile"></sharing>
      </div>
    </div>
    <div class="total-layout">
      <div class="total-wrap" v-for="(history, index) in histories" :key="index">
        <div class="img-wrap">
          <img width="55" :src="history.image">
        </div>
        <div class="content">
          <div class="total-title">{{ history.title }}</div>
          <div class="count">{{ history.count }}</div>
        </div>
      </div>
    </div>
    <template v-if="$store.getters.isNgo">
      <div class="title-wrap">
        <h3 class="page-title">
          Drop off address
        </h3>
      </div>
      <v-list class="ngo-card pb-0" three-line subheader v-for="dropoff in $store.state.dropoffs.all" :key="dropoff.id">
        <v-list-item class="px-6">
          <v-list-item-content class="py-5">
            <v-list-item-title>
              <span class="title primary--text">{{ dropoff.nameEn }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span class="subtitle-1 secondary--text">{{ dropoff.addressFullEn }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <div class="profile-action mt-10">
      <v-btn @click="$store.dispatch('logout')" rounded class="btn-start mb-4" color="secondary" height="35" width="90">
        {{ $t("me.logout") }}
      </v-btn>
      <v-btn text to="/privacy" small color="secondary" class="btn-privary">{{ $t("me.privacy") }}</v-btn>
    </div>
  </div>
</template>

<script>

import Sharing from '@/components/sharing';

export default {
  name: 'user',
  components: { Sharing },
  metaInfo: {
    title: 'Profile'
  },
  data: () => ({
    social: false
  }),
  computed: {
    histories () {
      return [
        {
          image: '/images/save-bread.svg',
          title: this.$t('history.total'),
          count: this.$store.state.auth.stats.totalSavedAmount
        },
        {
          image: '/images/runs.svg',
          title: this.$t('history.totalruns'),
          count: this.$store.state.auth.stats.totalRunsCompleted
        }
      ];
    },
    title () {
      return this.currentUser.name + ' has saved ' + this.$store.state.auth.stats.totalSavedAmount + ' bread and done ' +
          this.$store.state.auth.stats.totalRunsCompleted + ' runs with Breadline! Join us!';
    },
    shareUrl () {
      return location.origin + '/share.html';
    }
  }
};
</script>
